import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollTop from './ScrollTop';
import Home from './Home';
import Item from './Item';
import Category from './Category';
import ItemDetail from './ItemDetail';
import Thankyou from './Thankyou';
import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';
import Cart from './Cart';
import CommonComponent from './CommonComponent';
import './App.css';

const itemArray = require('./items.json');
const categoryArray = require('./categories.json');
export const UserCart = React.createContext()

const App = () => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent('react-component-did-mount'));
    console.log('😎ComponentDidMount on react')
  }, [])

  const handleSetCart = (newItem) => {
    if (cart) {
      setCart([...cart, newItem])
    } else {
      setCart([ newItem ])
    }
  };
  const value = {
    cart,
    setCart,
    handleSetCart,
    itemArray,
    categoryArray,
  }

  return (
    <BrowserRouter>
      <UserCart.Provider value={value}>
        <ScrollTop>
          <Switch>
            <Route exact path="/" render={() => 
              <>
                <Header />
                <Hero title="ホーム"/>
                <CommonComponent />
                <Home />
                <Footer />
              </>
            } />
            <Route exact path="/category" render={(props) => 
              <>
                <Header />
                <Hero title="商品一覧"/>
                <CommonComponent />
                <Category {...props} />
                <Footer />
              </>
            } />
            <Route exact path="/item/:id" render={(props) => 
              <>
                <Header />
                <CommonComponent />
                <ItemDetail {...props} />
                <Footer />
              </>
            } />
            {
              categoryArray.map((categoryInfo, index) => 
                <Route key={index + categoryInfo.category}
                  exact path={'/item/' + categoryInfo.category} 
                  render={(props) => 
                    <>
                      <Header />
                      <CommonComponent />
                      <Item {...props} />
                      <Footer />
                    </>
                  }  />
                )
            }
            <Route exact path="/thank-you" render={(props) => 
              <>
                <Header />
                <Hero title="Thank you"/>
                <CommonComponent />
                <Thankyou {...props} />
                <Footer />
              </>
            } />
            <Route exact path="/cart" render={(props) => 
              <>
                <Header />
                <Hero title="カート"/>
                <CommonComponent />
                <Cart {...props} />
                <Footer />
              </>
            } />
          </Switch>
        </ScrollTop>
      </UserCart.Provider>
    </BrowserRouter>
  );
}

export default App;
