import React, { useState, useEffect, useContext } from 'react'
import { UserCart } from './App'
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';
import './App.css';
import './ItemDetail.css';
import item01 from './img/item01.jpg';
import item02 from './img/item02.jpg';
import item03 from './img/item03.jpg';
import item04 from './img/item04.jpg';
import item05 from './img/item05.jpg';
import item06 from './img/item06.jpg';
import item07 from './img/item07.jpg';
import item08 from './img/item08.jpg';
import item09 from './img/item09.jpg';
import item10 from './img/item10.jpg';
import item11 from './img/item11.jpg';

const itemImageObj = { item01, item02, item03, item04, item05, item06, item07, item08, item09, item10, item11 };

const Cart = (props) => {
  const {
    cart,
    setCart,
  } = useContext(UserCart);

  const reducer = (accumulator, curr) => {
    console.log('accumulator',accumulator)
    console.log('curr',curr)
    return accumulator + parseFloat(curr.price.replace(/\$|,/g, ''))
  };
  const sum = cart.reduce(reducer, 0);

  const [loading, setLoad] = useState(false);

  const fakePurchase = () => {
    setLoad(true)
    setTimeout(() => {
        let path = `thank-you`; 
        setCart([]);
        props.history.push(`/${path}`);
    }, 700)
  }

  const removeItem = (item) => {
    cart.forEach((currentItem, cartIndex) => {
      if (currentItem.title === item.title) {
        cart.splice(cartIndex, 1);
      }
    })
    setCart([...cart]);
  }

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem('cartSumDemo')))
  }, [])

  useEffect(() => {
    localStorage.setItem('cartSumDemo', JSON.stringify(cart));
  }, [cart])

  return (
    <>
      <div className="container">
        <div className="cartItem">
          <div className="cartItem__list">
            {
              cart?.length > 0
                ? cart.map((item, index) => (
                    <div key={index + item.title} className="cartItem__single">
                      <div className="removeItem" onClick={() => removeItem(item)} ></div>
                      <div className="cartItem__single__image">
                        <img src={itemImageObj[item.url]} />
                      </div>
                      <div className="cartItem__single__info">
                        <h1 className="cartItem__title">{item.title}</h1>
                        <p className="cartItem__descr">{item.descr}</p>
                        <p className="cartItem__price">{item.price}</p>
                      </div>
                    </div>
                  )
                )
                :
                (
                  <div className="text-center">
                    <div className="cart__message">No Items in the Cart 😢</div>

                    <div className="thankyou__link">
                      <Link to="/"> {'<'} Back to Home</Link>
                    </div>
                  </div>
                )
            }
          </div>
          <div className="purchase-info">
            <div className="purchase-button">
              {
                cart?.length
                  ?
                  loading
                    ? <button className="loading-button" style={{ width: '154px', height: '57px' }}>
                      <div className="loader"></div>
                    </button>
                    : <button onClick={() => {
                      fakePurchase()
                    }}>Purchase</button>
                  : null
              }
            </div>
            {
              cart?.length > 0 ? <div className="purchaseTotal">Total: ${sum}.00</div> : null
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
