import React from 'react';
import { Link } from 'react-router-dom';
import category01 from './img/category01.png';
import category02 from './img/category02.png';
import category03 from './img/category03.png';
import category04 from './img/category04.png';
import category05 from './img/category05.png';
import category06 from './img/category06.png';
import './Header.css';

const categoryImageArray = [
  category01,
  category02,
  category03,
  category04,
  category05,
  category06,
]

const CategoryList = ({ categoryInfo }) => {
  return (
    <div className="itemList">
      <ul>
        {
          categoryInfo.map((item, index) => (
              <li key={index + '_' + item.name} className="itemList">
                <Link to={`/category?type=${item.category}`}>
                  <img src={categoryImageArray[index]} />
                  <p>{item.name}</p>
                </Link>
              </li>
            )
          )
        }
      </ul>
    </div>
  );
}

export default CategoryList;
