import React,{ useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs';
import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';
import ItemList from './ItemList';
import { UserCart } from './App'

const Category = (props) => {
  const {
    cart,
    itemArray,
    categoryArray
  } = useContext(UserCart);
  const categoryTypeObj = qs.parse(props.location.search);
  const categoryType =  categoryTypeObj['?type'] ? categoryTypeObj['?type'] : 'all';
  const [ category, setCategory ] = useState(categoryType);
  const location = useLocation();

  const handleSetCategory = (e) => {
    setCategory(e.target.value);
    props.history.push({
      search: '?type=' + e.target.value,
    })
  }
  
  useEffect(() => {
    setCategory(categoryType);
  }, [location]);

  const list = itemArray.filter(item => {
    if (category === 'all') {
      return true;
    } else {
      return item.category === category
    }
  })

  return (
    <>
      <div className="container">

        <div className="filterCategory__container">
          {
            list?.length === 0 
             ? null
             : <select value={category}
                onChange={(e) => handleSetCategory(e)} className="filterCategory">
                <option value="all">All</option>
                {
                  categoryArray.map((item, index) => {
                    return <option value={item.category} key={index + item.category}>{item.name}</option>
                  })
                }
              </select>
          }
        </div>

        <div id="target01"></div>

        <ItemList
          category={category}
          list={list}
        />

      </div>
    </>
  );
}

export default Category; 
