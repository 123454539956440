import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { UserCart } from './App'
import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';
import CategoryList from './CategoryList';

const Home = () => {
  const { setCart, categoryArray } = useContext(UserCart);

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem('cartSumDemo')))
  }, [])

  return (
    <>
      <div className="container">

        <div className="itemlist__link">
          <Link to="/category?type=all">全商品一覧をみる {' >'}</Link>
        </div>

        <div className="categoryWrapper--home">
          <CategoryList
            categoryInfo={categoryArray}
          />
        </div>

      </div>
    </>
  );
}

export default Home;
