import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollTop = ({ history, children }) => {
    useEffect(() => {
        const unListen = history.listen(() => {
            window.scroll(0, 0);
        })
        return () => {
            unListen();
        }
    }, [])
    return <>{children}</>;
}

export default withRouter(ScrollTop);
