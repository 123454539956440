import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import Footer from './Footer';
import CategoryList from './CategoryList';
import { UserCart } from './App'
import item01 from './img/item01.jpg';
import item02 from './img/item02.jpg';
import item03 from './img/item03.jpg';
import item04 from './img/item04.jpg';
import item05 from './img/item05.jpg';
import item06 from './img/item06.jpg';
import item07 from './img/item07.jpg';
import item08 from './img/item08.jpg';
import item09 from './img/item09.jpg';
import item10 from './img/item10.jpg';
import item11 from './img/item11.jpg';
import './App.css';
import './ItemDetail.css';

const itemImageObj = { item01, item02, item03, item04, item05, item06, item07, item08, item09, item10, item11 };

const ItemDetail = (props) => {
  let currentItem;
  
  const {
    cart,
    setCart,
    handleSetCart,
    itemArray,
    categoryArray
  } = useContext(UserCart);

  const [loading, setLoad] = useState(false);

  const getItemId = () => {
    const pagePath = props.location.pathname.split('/');
    return pagePath[pagePath.length - 1];
  }

  const currentItemArray = itemArray.filter(item => item.url === getItemId())

  const addToCart = (item) => {
    setLoad(true);
    toast(`${item.title} was added to the cart!`);
    setTimeout(() => {
      handleSetCart(item);
      setLoad(false);
    }, 1000)
  }

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem('cartSumDemo')))
  }, [])

  useEffect(() => {
    localStorage.setItem('cartSumDemo', JSON.stringify(cart));
  }, [cart])

  if (currentItemArray.length !== 0) {
    currentItem = currentItemArray[0];
  }

  // window.Evergage.sendEvent({
  //   name: 'View Item',
  //   itemAction: window.Evergage.ItemAction.ViewItem,
  //   catalog: {
  //       Product: {
  //         _id: currentItem.id,
  //         imageUrl: currentItem.imgUrl,
  //         price: currentItem.price,
  //       }
  //   }
  // })

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        closeOnClick={true}
      />
      <div className="container">
        <div className="itemDetail">
          <div className="itemDetail__image">
            <img src={itemImageObj[currentItem.url]} />
          </div>
          <div className="itemDetail__info">
            <h1>{currentItem.title}</h1>
            <p className="itemDetail__price">{currentItem.price}</p>
            <p className="itemDetail__descr">{currentItem.descr}</p>
            <div className="itemDetail__buttonWrapper">
              {
                !loading
                  ? <button onClick={() => {
                      addToCart(currentItem)
                    }}>Add To Cart</button>
                  : <button className="loading-button" style={{width: '154px', height: '57px'}}>
                      <div className="loader"></div>
                    </button>
              }
              {
                cart.length !== 0
                  ?  <Link className="cart-button" to={{
                    pathname: "/cart",
                  }}>Check My Cart</Link>
                  : null
              }
            </div>
          </div>
        </div>
      </div>

      <div className="container">

        <div className="categoryWrapper--home">
          <CategoryList
              categoryInfo={categoryArray} />
        </div>

      </div>
    </>
  );
}

export default ItemDetail;
