import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';

const Thankyou = () => {
  return (
    <>
      <div className="container">

        <div id="target01"></div>

        <div className="thankyou__link">
          <Link to="/"> {'<'} Back to Home</Link>
        </div>

        <div id="target02"></div>

      </div>
    </>
  );
}

export default Thankyou;
