import item01 from './img/item01.jpg';
import item02 from './img/item02.jpg';
import item03 from './img/item03.jpg';
import item04 from './img/item04.jpg';
import item05 from './img/item05.jpg';
import item06 from './img/item06.jpg';
import item07 from './img/item07.jpg';
import item08 from './img/item08.jpg';
import item09 from './img/item09.jpg';
import item10 from './img/item10.jpg';
import item11 from './img/item11.jpg';
import Header from './Header';
import Footer from './Footer';
import ItemList from './ItemList';
import React, { useState } from 'react';

const itemInfo = [
  {
    imageUrl: item01,
    id: '001',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item01.05640e2e.jpg',
    url: 'item01',
    title: 'Pullover Hoodie',
    category: 'women',
    price: '$50.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item02,
    id: '002',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item02.b0b3f9f1.jpg',
    url: 'item02',
    title: 'Milan Sneakers',
    category: 'footwear',
    price: '$100.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item03,
    id: '003',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item03.96f6031d.jpg',
    url: 'item03',
    title: 'Breakaway Hat',
    category: 'women',
    price: '$80.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item04,
    id: '004',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item04.12abe755.jpg',
    url: 'item04',
    title: 'Women’s North Dome Pants',
    category: 'women',
    price: '$200.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item05,
    id: '005',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item05.3536d890.jpg',
    url: 'item05',
    title: 'Men’s Tri-blend Baseball Tee',
    category: 'men',
    price: '$30.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item06,
    id: '006',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item06.83d020ff.jpg',
    url: 'item06',
    title: 'Hedgehog Agile NTO-tech',
    category: 'men',
    price: '$120.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item07,
    id: '007',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item07.f8eb4823.jpg',
    url: 'item07',
    title: 'Muddier Trucker',
    category: 'men',
    price: '$28.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item08,
    id: '008',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item08.1afd66d2.jpg',
    url: 'item08',
    title: 'Women’s North Casual Pants',
    category: 'women',
    price: '$90.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item09,
    id: '009',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item09.bf127ca9.jpg',
    url: 'item09',
    title: 'Infant Campfire One-piece',
    category: 'kids',
    price: '$70.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item10,
    id: '010',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item10.7538b658.jpg',
    url: 'item10',
    title: 'Route Rocket',
    category: 'gear',
    price: '$80.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
  {
    imageUrl: item11,
    id: '011',
    imgUrl: 'https://spa-sp-demo.herokuapp.com/static/media/item11.23ef3ad4.jpg',
    url: 'item11',
    title: 'BigfootBar Blueberry 2oz bar',
    category: 'nutrition',
    price: '$22.00',
    descr: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia aliquet quam, vel elementum sem suscipit eget. Sed vel volutpat nisl, a pretium ex. Proin laoreet ante non dui scelerisque, a ullamcorper urna gravida. Proin consectetur purus enim, at facilisis massa eleifend et. Integer leo diam, pulvinar vitae tempus quis, condimentum sed ligula. Proin ullamcorper arcu commodo ante dapibus accumsan.'
  },
];

const Item = (props) => {    
  const pagePath = props.location.pathname.split('/');
  const pageName = pagePath[pagePath.length - 1];
  const category = pageName || props.location.query?.category;
  const [cartItem, setCartItem] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('');

  const setcategory = (e) => {
    setCurrentCategory(e.target.value);
  }

  const uniqueCategories = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  return (
    <>
      <div className="container">

        <div className="filterCategory__container">
          <select onChange={setcategory} className="filterCategory">
            <option value="all">All</option>
            {
              uniqueCategories(itemInfo, 'category').map((item, index) => (
                <option value={item.category} key={index + item.category}>{item.category}</option>
              ))
            }
          </select>
        </div>

        <ItemList
          handleOnClick={item => {
            setCartItem(oldState => [...oldState, item])
          }}
          cart={cartItem}
          pageType="item"
          category={currentCategory}
          itemInfo={itemInfo} />      
      </div>
    </>
  );
}

export default Item; 
