import React,{ useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserCart } from './App'
import './Header.css';
import item01 from './img/item01.jpg';
import item02 from './img/item02.jpg';
import item03 from './img/item03.jpg';
import item04 from './img/item04.jpg';
import item05 from './img/item05.jpg';
import item06 from './img/item06.jpg';
import item07 from './img/item07.jpg';
import item08 from './img/item08.jpg';
import item09 from './img/item09.jpg';
import item10 from './img/item10.jpg';
import item11 from './img/item11.jpg';

const itemImageObj = { item01, item02, item03, item04, item05, item06, item07, item08, item09, item10, item11 };

const ItemList = ({ list }) => {
  const { cart, setCart, handleSetCart } = useContext(UserCart);
  
  const [loading, setLoad] = useState(false);

  const addToCart = (item) => {
    setLoad(true);
    toast(`${item.title} was added to the cart!`);

    setTimeout(() => {
      handleSetCart(item);
      setLoad(false);
    }, 500)
  }

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem('cartSumDemo')))
  }, [])

  useEffect(() => {
    localStorage.setItem('cartSumDemo', JSON.stringify(cart));
  }, [cart])

  return (
    <div className={['itemList'].join(' ')}>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        closeOnClick={true}
      />
      
      <ul className="">
        {
          list?.length === 0
            ?  <li style={{ textAlign: 'center', flex: '1 0 100%', marginBottom: '40px', fontSize: '20px' }}>No Items Found 😢</li>
            :  list.map(
                (item, index) => (
                  <li key={index + '_' + item.name} className="itemList">
                    {
                      <>
                        <img src={itemImageObj[item.url]} />
                        <p>{item.name || item.title}</p>
                        <div className="itemList__buttonWrapper"> 
                          <Link to={{pathname: 'item/' + item.url }}>Show Details</Link>
                          <button onClick={() => addToCart(item)} className="listButton">
                            {
                              loading
                                ? <div className="loader"></div>
                                : <span>Add To Cart</span>
                            }
                          </button>
                        </div>
                      </>
                    }
                  </li>
                  )
                )
            }
      </ul>
    </div>
  );
}

export default ItemList;
