import logo from './img/logo.svg';
import React from 'react';
import { Link　} from 'react-router-dom';
import './Footer.css';

const Footer = () => (
  <div className="container-fluid container-footer">
    <div className="container">
      <footer className="footer">
        <div className="logo--footer">
          <Link to="/"><img src={logo} /></Link>
        </div>
      </footer>
    </div>
  </div>
);

export default Footer;
