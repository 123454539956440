import { Link } from 'react-router-dom';
import React,{useContext} from 'react'
import logo from './img/logo.svg';
import { UserCart } from './App'
import './Header.css';

const Header = () => {
  const { cart, handleSetCart } = useContext(UserCart);

  return (
    <div className="container-fluid container-header">
      <div className="container">
        <header className="header">
          <div className="logo">
            <Link to="/"><img src={logo} width="80" height="56" /></Link>
          </div>
          <div className="menu">
            <Link to="/">ホーム</Link>{' '}
            <Link to="/category">商品一覧</Link>{' '}
            <Link 
              to="/cart"
              className="menu__cart"
            >カート 
              <span className="menu__cart__num">{cart?.length ? cart.length : ''}</span>
            </Link>
          </div>
        </header>
      </div>

    </div>
  );
}

export default Header;
